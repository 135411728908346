import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { login, logout } from "../../utils/auth"
import {  Nav, NavLinks, CTA } from "../../styles/lib"
import { createSignalIfSupported } from "@apollo/client"

export default function Navbar({ user }) {

    if(user) {
        return(
            <Nav>
                <h1>Student Centric</h1>
                <NavLinks>
                    <Link to="/account/dashboard">Dashboard</Link>
                    <Link>Notifications</Link>
                    <Link to="/account">Account</Link>
                    <CTA onClick={e => {
                    logout()
                    e.preventDefault()
                  }}>Logout</CTA>
                </NavLinks>
            </Nav>
            )
    }
    else {
        return (
            <Nav>
                <h1>Student Centric</h1>
                <NavLinks>
                    <Link to="/">Overview</Link>
                    <Link to="/">About</Link>
                    <Link to="/account">Pricing</Link>
                    <CTA onClick={e => {
                    login()
                    e.preventDefault()
                  }}>Login</CTA>
                </NavLinks>
            </Nav>
        )
    }
}
