import styled from "styled-components"
import { motion } from "framer-motion"

export const PageWrapper = styled(motion.div)`
  font-family: Gotham thin;
  padding-top: 10vh;
  margin-left: auto;
  margin-right: auto;
  max-width: 1500px;
  padding-left: 5vw;
  padding-right: 5vw;
  place-items: center center;

  h2 {
    font-weight: 800;
    font-size: 36px;
  }
  
  @media(max-width: 800px) {
    padding-left: 1vw;
    padding-right: 1vw;
  }
  `

export const CourseGallery = styled.div`
  display: grid;
  

  input {
    place-self: start start;
    padding: 5px 40px;
    margin-bottom: 20px;
    border-radius: 13px;
    font-family: Gotham Thin;
    font-weight: 800;
  }

  a {
        text-decoration: none;
        color: black;
        border: solid 1px black;
        padding: 15px 40px;
        border-radius: 13px;
        place-self: start start;
        transition: ease-in-out .3s;

        &:hover {
            color: white;
            background: black;
            cursor: pointer;
        }
    }
`

export const Breadcrumb = styled.ul`
  list-style: none;
  font-family: Gotham black;
  font-weight: 600;
  margin-bottom: 10vh;
  padding-left: 0;

  li {
    display: inline;
    padding-left: 0;
    margin-left: 0;

    &:not(:first-child):before {
    padding: 8px;
    color: black;
    content: " > ";
  }
  }


  a {
  display: inline;
  text-decoration: none;
  border-bottom: solid 2px transparent;
  color: black;
  font-weight: 800;
  transition: ease-in-out .3s;
  
  &:hover {
    border-bottom: solid 2px black;
  }
}
`

export const Current = styled.p`
display: inline;
  color: grey;
`

export const Nav = styled.div`
    display: grid;
    grid-template-columns: 6fr 3fr;
    min-height: 10vh;
    place-content: center center;
    font-family: Gotham black;

    h1 {
        padding-left: 4vw;
    }

    a {
        text-decoration: none;
        color: black;
        border-bottom: solid 2px transparent;
        transition: ease-in-out .3s;
        padding-left: 1vw;
        padding-right: 1vw;
        
        &:hover {
            border-bottom: solid 2px black;
            cursor: pointer;
        }
    }

    @media(max-width: 800px) {
        grid-template-columns: 1fr;
    }
    `

export const NavLinks = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    place-items: center center;
`

export const CTA = styled.div`
    border: solid 1px black;
    padding: 15px 40px;
    border-radius: 13px;
    transition: ease-in-out .3s;

    &:hover {
        background: black;
        color: white;
        cursor: pointer;
    }

    a {
        text-decoration: none;

        &:hover {
            color: white;
            border-bottom: solid 2px transparent;
        }
    }
`

export const TwoColumn = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  font-family: Gotham black;
  place-items: center center;
  margin-bottom: 200px;

  a {   
        margin-top: 5vh;
        font-size: 2vw;
        font-weight: 400;
        text-decoration: none;
        text-transform: uppercase;
        color: black;
        border: solid 2px black;
        transition: ease-in-out .3s;
        padding: 15px 40px;
        border-radius: 13px;
        
        &:hover {
            background: rgba(248, 109, 112, 1);
            border: solid 2px white;
            color: white;
            cursor: pointer;
        }

        @media(max-width: 800px) {
          font-size: 5vw;
        }
    }
  

  img {
    width: 100%;
    height: auto;
  }

  @media(max-width: 800px) {
    grid-template-columns: 1fr;
  }

  @media(min-width: 1200px) {
    place-items: center start;
    p {
      font-size: 1vw !important; 
    }
  }
`

export const Headline = styled.div`
  font-family: Gotham black;
  font-weight: 400;
  font-size: 4vw;
  margin-bottom: 5vh;

  span {
    color: rgba(248, 109, 112, 1)
  }

  @media(max-width: 800px) {
    font-size: 10vw;
  }
`

export const SectionHeader = styled.div`
  display: grid;
  font-family: Gotham black;
  align-items: start;
  
  h2 {
    font-weight: 400;
    font-size: 3vw;
    margin-bottom: 5vh;

  span {
    color: rgba(248, 109, 112, 1)
  }

  @media(max-width: 800px) {
    font-size: 10vw;
  }

  }

  p {
    font-family: Gotham light;
    font-size: 1.5vw;
    font-weight: 600;
    line-height: 1.4;

    @media(max-width: 800px) {
    font-size: 4vw;
  }
  }
`

export const PageSqueeze = styled.div`
  display: grid;
  max-width: 1000px;
  margin: auto;

  a {   
        margin-bottom: 5vh;
        font-size: 2vw;
        font-weight: 400;
        font-family: Gotham black;
        text-decoration: none;
        text-transform: uppercase;
        color: black;
        border: solid 2px black;
        transition: ease-in-out .3s;
        padding: 15px 40px;
        border-radius: 13px;
        
        &:hover {
            background: rgba(248, 109, 112, 1);
            border: solid 2px white;
            color: white;
            cursor: pointer;
        }

        @media(max-width: 800px) {
          font-size: 5vw;
        }
    }
`

export const QuizDetail = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  place-items: center center;
  max-width: 50%;
  min-height: 200px;
  font-family: Gotham black;
  padding: 15px 45px;
  border-radius: 13px;
  margin-bottom: 40px;

  @media(max-width: 800px) {
    grid-template-columns: 1fr;
    place-items: start start;
  }
`

export const AccountContainer = styled.div`
  display: grid;
  width: 100%;
  place-items: center center;
  max-width: 800px;
  margin: auto;

  a {   
        margin-bottom: 5vh;
        font-size: 1vw;
        font-weight: 400;
        text-align: center;
        font-family: Gotham black;
        text-decoration: none;
        text-transform: uppercase;
        color: black;
        border: solid 2px black;
        transition: ease-in-out .3s;
        padding: 15px 40px;
        border-radius: 13px;
        
        &:hover {
            background: rgba(248, 109, 112, 1);
            border: solid 2px white;
            color: white;
            cursor: pointer;
        }
  }


  img {
    width: 100%;
    height: auto;
  }
`

export const CourseDashContainer = styled.div`
  display: grid;


  a {
        text-decoration: none;
        color: black;
        transition: ease-in-out .3s;
        padding-left: 1vw;
        padding-right: 1vw;
        
        &:hover {
            color: #8f8e8e;
            cursor: pointer;
        }
    }
`

export const DashChallengeContainer = styled(motion.table)`
  font-family: Gotham Black;
  min-width: 100%;
  border-collapse: collapse;
  margin-bottom: 80px;
  border-radius: 13px;
  box-shadow: 0 6px 8px rgba(0,0,0,.4);
  text-align: left;
  
  th {
    font-weight: 800;
    font-size: 24px;
    background: black;
    color: white;
    padding: 5px 10px;
  }

  td {
    font-size: 16px;
    padding: 5px 10px;
    border-bottom: solid 1px black;
  }

  tr:last-child {
    td {
      border-bottom: none;
    }
}
  `

export const GraphContainer = styled.div`
  display: grid;
  grid-template-columns: fit-content(200px) 250px 250px 250px;
`

export const DetailsAction = styled.div`
    a {
        color: black;
        font-weight: 800;
        font-family: Gotham black;
        text-transform: uppercase;
        border: solid 1px black;
        padding: 15px 40px;
        border-radius: 13px;
        transition: ease-in-out .3s;
        text-decoration: none;

        &:hover {
          background: black;
          color: white;
        }
    }
`